import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-lazy-load-image-component/src/effects/blur.css";
// components
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlexCard from "../components/flexcard";
import DrivingSchoolCard from "../components/drivingschoolcard";
import HomeVideoBackground from "../components/homevideobackground";
import Track from "../components/track";
import settings from "../../settings";
import { showErrorToast } from "../helpers/toast";

// images
const poster = `${settings.IMAGES_BASE_URL}/v2/images/poster_home.jpg`;
const teenschool = `${settings.IMAGES_BASE_URL}/v2/images/bmw-teen-school.jpg`;
const driverschool = `${settings.IMAGES_BASE_URL}/v2/images/bmw_driver_school.jpg`;
const mschool = `${settings.IMAGES_BASE_URL}/v2/images/bmw_m_school.jpg`;
const bimmerbeliever = `${settings.IMAGES_BASE_URL}/v2/images/indy_images/bmw-aftersales.jpg`
const mMixedRealityBanner = `${settings.IMAGES_BASE_URL}/v2/images/m-mixed-reality-home-banner.jpg`;
const mSummerBanner = `${settings.IMAGES_BASE_URL}/v2/images/indy_images/m-summer-competition.jpg`;
const caOfferBanner = `${settings.IMAGES_BASE_URL}/v2/images/bmw-summer-offer.jpg`;

// const captions = `${settings.IMAGES_BASE_URL}/v2/captions/homepage-video-captions.vtt`;

const today = new Date();
const caOffer = today < new Date(2024,8,21);

const IndexPage = () =>  {

  const getFirstCard = () => { 
    if (caOffer) {
      return (
        <>
          <FlexCard
              rowType="row"
              image={caOfferBanner}
              imageAlt="BURN RUBBER THIS SUMMER"
              header="BURN RUBBER THIS SUMMER"
              subheader="GET 25% OFF A CLASS OR EXPERIENCE"
              text="Summer’s always a big deal, but with 25% off a class or Experience at the BMW Performance Center West in Thermal, California, it’s bound to heat up even more."
              btnText="Learn More"
              btnStyle="btn-blue"
              route="/driverschool"
          >
            <p>
              Offer valid through 9/30/2024 in Thermal location only. Must take the class by 9/30/2024. Excludes the M4 GT4 Experience, Race License School, M Summer Competition, M Mixed Reality and Private Instruction.<br/><br/>
              Call <a className='phone-number' href='tel:888-345-4269'>888-345-4269</a> to book, and use code <span className="bold">24BHPCA25</span>.
            </p>
          </FlexCard>
        </>
      )
    } else {
      return (
        <>
          <FlexCard
              rowType="row"
              image={mSummerBanner}
              imageAlt="BEST FRENEMIES"
              header="BEST FRENEMIES"
              subheader="M SUMMER COMPETITION IS HERE"
              text="Our newest Experience invites you to hop into some M cars and take on the competition with timed laps and drag racing."
              btnText="Learn More"
              btnStyle="btn-blue"
              route="/experiences"
          />
        </>
      )
    }
  }
  
  const getSecondCard = () => {
    if (caOffer) {
      return (
        <>
          <FlexCard
              rowType="row-reverse"
              image={mSummerBanner}
              imageAlt="BEST FRENEMIES"
              header="BEST FRENEMIES"
              subheader="M SUMMER COMPETITION IS HERE"
              text="Our newest Experience invites you to hop into some M cars and take on the competition with timed laps and drag racing."
              btnText="Learn More"
              btnStyle="btn-blue"
              route="/experiences"
          />

          <FlexCard
            rowType="row"
            image={mMixedRealityBanner}
            imageAlt="WELCOME TO THE VIRTUAL REAL WORLD"
            header="WELCOME TO THE VIRTUAL REAL WORLD"
            subheader="M MIXED REALITY IS HERE"
            text="Hop into a BMW M car, slip on a headset, and drive it through a virtual track. This isn’t fantasy, and this isn’t reality. It’s both."
            btnText="Learn More"
            btnStyle="btn-blue"
            route="/experiences"
          >
            <p>
              M Mixed Reality is led by professional driving instructors on a closed course, with customized vehicle technology specific to this experience.
            </p>
            </FlexCard>
        </>
      )
    } else {
      return (
        <>
          <FlexCard
            rowType="row-reverse"
            image={mMixedRealityBanner}
            imageAlt="WELCOME TO THE VIRTUAL REAL WORLD"
            header="WELCOME TO THE VIRTUAL REAL WORLD"
            subheader="M MIXED REALITY IS HERE"
            text="Hop into a BMW M car, slip on a headset, and drive it through a virtual track. This isn’t fantasy, and this isn’t reality. It’s both."
            btnText="Learn More"
            btnStyle="btn-blue"
            route="/experiences"
          >
            <p>
              M Mixed Reality is led by professional driving instructors on a closed course, with customized vehicle technology specific to this experience.
            </p>
          </FlexCard>

          <FlexCard
            rowType="row"
            image={bimmerbeliever}
            imageAlt="BIMMER BELIEVER"
            header="BIMMER BELIEVER"
            subheader="BMW ACCESSORIES AND LIFESTYLE"
            text="Whether it’s an M pen or M Power, you’ll find excitement waiting to be added with Genuine BMW Accessories at ShopBMWUSA.com."
            btnText="Shop now"
            btnStyle="btn-blue"
            externalURL="http://www.shopbmwusa.com?utm_source=bmwperformancecentercom&utm_medium=FMAbanner&utm_campaign=AFTERfebmar"
          />
        </>
      )
    }
  }

  return (
      <Layout>
        <SEO title="BMW Performance Driving School" description="Learn total car control behind the wheel of a BMW at the BMW Performance Driving School. Multiple class offerings and challenges await in one of our three locations." />
        <div className="homepage">
          <HomeVideoBackground
            url={`${settings.IMAGES_BASE_URL}/v2/videos/home-video-opening-2022.mp4`}
            poster={poster}
            hideMobileHeader={true}
            topLeftChildren={
              <div className="text_left">
                <div>DRIVE ON THE EDGE OF PHYSICS.</div>
              </div>
            }
          />
          <section className="performance-center">
            <div className="container">
              <div className="drivingschool__header">
                <h2>THE BMW PERFORMANCE DRIVING SCHOOL</h2>
                <p>
                  An open track, the Ultimate Driving Machine, and you in the driver’s seat. <br/>
                  Prepare for a BMW driving experience like no other.
                </p>
              </div>
            </div>
            {getFirstCard()}
            {getSecondCard()}
          </section>
          <Track variant="white" />
          <section className="drivingschool">
            <div className="container">
              <div className="drivingschool__header">
                <h2>BMW DRIVING SCHOOLS</h2>
              </div>
            </div>
            <DrivingSchoolCard
              rowType="row"
              image={teenschool}
              imageAlt="BMW Teen School 01"
              header="BMW TEEN SCHOOL"
              subheader="UNDERSTANDING THE BASICS"
              text="Leave your perception of driver’s education class at the door: this isn’t about parallel parking. Teens will learn total car control at the limit on our closed track, making them much more alert and aware. LOLs are encouraged."
              learnUrl="/teenschool"
              learnBtnStyle="btn-bordered"
              bookBtnStyle="btn-blue"
              bookUrl="https://pds.eventsbmw.com/?brand=BMW&class=BMW_One_Day_Teen_School"
            />
            <DrivingSchoolCard
              rowType="row"
              image={driverschool}
              imageAlt="BMW Driver's School"
              header="BMW DRIVER’S SCHOOL"
              subheader="A THRILLING BMW DRIVING EXPERIENCE"
              text="Let’s bring you up to speed on the best ways to handle a BMW’s advanced capabilities with classes that focus on car control, proper vision, brake points and more. If your heart rate just went up, this is the class for you."
              learnUrl="/driverschool"
              learnBtnStyle="btn-bordered"
              bookBtnStyle="btn-blue"
              bookUrl="https://pds.eventsbmw.com/?brand=BMW&class=BMW_One_Day_School"
            />
            <DrivingSchoolCard
              rowType="row"
              image={mschool}
              imageAlt="BMW M School"
              header="BMW M SCHOOL"
              subheader="IT’S PRACTICALLY A BMW RACE SCHOOL"
              text="Unleash the full potential of our M vehicles as you push them through high-speed stability, control and drifting exercises. Prepare for the ultimate drive."
              learnUrl="/mschool"
              learnBtnStyle="btn-bordered"
              bookBtnStyle="btn-blue"
              bookUrl="https://pds.eventsbmw.com/?brand=M&class=One_Day_M_School"
            />
          </section>
        </div>
      </Layout>
    );
}

export default IndexPage;